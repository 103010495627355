<template>
  <v-app>
    <v-app-bar color="primary" dark app>
      <v-app-bar-nav-icon @click="$router.go(-1)">
        <v-icon>chevron_left</v-icon>
      </v-app-bar-nav-icon>
      <v-app-bar-title>详情</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn color="purple darken-3" fab small @click="isEditing = !isEditing">
        <v-icon v-if="isEditing">close</v-icon>
        <v-icon v-else>edit</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-card elevation="4" outlined class="ma-4 pb-8">
        <v-card-title> {{ form.name }} </v-card-title>
        <v-card-subtitle> </v-card-subtitle>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="form.name"
              label="名称"
              :disabled="!isEditing"
              prepend-inner-icon="storefront"
              type="text"
              :rules="rules.name"
            />
            <v-text-field
              v-model="form.contacts"
              label="联系人"
              :disabled="!isEditing"
              prepend-inner-icon="person_outline"
              type="text"
              :rules="rules.contacts"
            />
            <v-text-field
              v-model="form.contact_phone"
              label="联系电话"
              :disabled="!isEditing"
              prepend-inner-icon="dialpad"
              type="number"
              :rules="rules.contact_phone"
            />
            <v-text-field
              v-model="form.total_text"
              label="累计充次"
            disabled
              prepend-inner-icon="functions"
              type="text"              
            />
            <v-text-field
              v-model="form.used_text"
              label="累计使用"
              disabled
              prepend-inner-icon="touch_app"
              type="text"              
            />
            <v-text-field
              v-model="form.has_text"
              label="剩余次数"
              disabled
              prepend-inner-icon="slow_motion_video"
              type="text"              
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="3"
            rounded
            @click="submitdata"
            v-if="isEditing && pageType == 'detail'"
            >提交修改</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-overlay :value="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="isLoading"
          size="70"
          :width="3"
        ></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>
<script>

import { shop_info, shop_edit } from "@/api/user";
export default {
  name: "shopInfo",
  data() {
    return {
      hasSaved: false,
      isEditing: true,
      dialog: false,
      isLoading: false,
      form: {
        id: null,
        name: "",
        contacts: "",
        contact_phone: "",
        total_text: "",
        used_text: "",
        has_text: "",
        state: 1,
      },
      pageType: "add",
      companies: [],
      shops: [],
      rules: {
        name: [(v) => !!v || "名称不能为空"],
        contact_phone: [
          (v) => !!v || "员工手机号不能为空",
          (v) => /^[1][0-9][0-9]{9}$/.test(v) || "手机号格式不正确",
        ],
      },
    };
  },

  beforeRouteLeave(to, from, next) {
    to.query.hasSaved = this.hasSaved;
    next();
  },

  created() {    
    const route = this.$route;
    if (route.query.id) {
      this.isEditing = false;
      this.pageType = "detail";
      this.fetchData(route.query.id);
      this.pageType = "detail";
    }    
  },
  methods: {
    async submitdata() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        let data = {};
        data = await shop_edit(this.form).finally(() => {
          this.isLoading = false;
        });

        if (data) {
          this.form = data.data;          
          this.isEditing = false;
          this.pageType = "detail";
          this.hasSaved = true;
          this.$toast.success(data.message);
          this.$router.go(-1);
        }
      }
    },
     async fetchData(id) {
      this.isLoading = true;
      let res = await shop_info(id);
      this.form = res.data;
      this.form.total_text=this.form.mode1Total+'次(模式一),'+this.form.mode2Total+'次(模式二)';
      this.form.used_text=this.form.used1+'次(模式一),'+this.form.used2+'次(模式二)';
      let has1=this.form.mode1Total-this.form.used1;
      let has2=this.form.mode2Total-this.form.used2;
      this.form.has_text=has1+'次(模式一),'+has2+'次(模式二)';
      this.isLoading = false;

    },
  },
   
};
</script>
