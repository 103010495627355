<template>
  <v-app>
    <v-app-bar color="deep-purple" dark app>
      <v-app-bar-nav-icon>
        <v-icon>handshake</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>霜钻天使之颜</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-list>
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-avatar color="primary" size="50">
                <span class="white--text text-h4">{{
                  userInfo.name?userInfo.name.slice(0, 1):""
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
            <v-list-item-title class="text-h6">{{userInfo.name}}</v-list-item-title>
            <v-list-item-subtitle class="mt-2"><v-icon small color="">phone_iphone</v-icon>
                {{ userInfo.phone }}<span class="primary--text font-weight-bold pl-2 pr-2">|</span>            
                <v-icon small color="">storefront</v-icon>
                {{ userInfo.shop_name }}</v-list-item-subtitle>
            
          </v-list-item-content>
          </v-list-item>
          
        </v-list>
        <v-divider></v-divider>
        <v-list flat>
          <v-list-item-group color="primary">
            <v-list-item @click="showCamera = true">
              <v-list-item-icon>
                <v-icon color="primary">qr_code_scanner</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>扫码启动</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item to="/operation_records">
              <v-list-item-icon>
                <v-icon color="primary">format_list_bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>操作记录</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item to="/admin" v-if="userInfo.role == 2">
              <v-list-item-icon>
                <v-icon color="primary">settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="ml-n4">
                <v-list-item-title>管理</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>chevron_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            
            <v-list-item class="mt-16" active-class="">
              <v-list-item-content>
                <v-btn                  
                  color="primary"
                  elevation="3"
                  rounded
                  large
                  @click="signout"
                  >注销退出</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-dialog v-model="showCamera" width="500">
          <v-card :loading="!loadStream">
            <v-card-title class="text-h6 grey lighten-2">
              开始扫码
            </v-card-title>

            <v-card-text class="pa-2">
              <StreamQrcodeReaderVue
                :showCamera="showCamera"
                @decode="onDecode"
                @loaded="onLoaded"
                ref="StreamQrcode"
              />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="showCamera = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
    <v-overlay :value="loading">
        <v-progress-circular indeterminate color="primary" style="
                                  position: absolute;
                                  top: 20%;
                                  left: 50%;
                                  transform: translate(-50%, -50%);
                                " v-if="loading" size="70" :width="3"></v-progress-circular>
      </v-overlay>
  </v-app>
</template>

<script>
import StreamQrcodeReaderVue from "@/components/StreamQrcodeReader.vue";
import { userinfo ,logout } from "@/api/user.js";
export default {
  name: "Home",

  components: {
    StreamQrcodeReaderVue,
  },

  data: () => ({
    code: null,
    showCamera: false,
    loadStream: false,
    loading: false,
    userInfo: {
      name: "",
      phone: "",
      shop_name: "",
      shop_id: null,
      role: 1,
    },
  }),

  beforeRouteLeave(to, from, next) {
    if (this.$refs.StreamQrcode != undefined) {
      this.$refs.StreamQrcode.codeReader.reset();
    }
    next();
  },

  watch: {
    showCamera: function (val) {
      if (!val) {
        this.loadStream = false;
      }
    },
  },

  created() {
    this.loading = true
    userinfo().then((res) => {        
      if ( res.code != 200  ) {
        return false;
      }
      this.userInfo = res.data;
      this.loading = false
    });
  },

  methods: {
    add(code) {
      this.$router.push("/device_switch?code=" + code);
    },
    onDecode(result) {
      console.log(result);
      this.showCamera = false;
      this.$router.push("/device_switch?code=" + result);
    },
    getUserInfo() {
      userinfo();
    },
   async signout() {
      this.loading = true;
      await logout().then((res) => {
        console.log(res);
        localStorage.removeItem("token");
        localStorage.removeItem("Ismanager");
        this.loading = false;
        this.$router.replace("/login");
      });

    },
    onLoaded() {
      console.log("loaded");
      this.loadStream = true;
    },
  },
};
</script>

