/*
 * @Author: zhang_fly 15620636416@163.com
 * @Date: 2023-07-17 14:45:58
 * @LastEditors: zhang_fly 15620636416@163.com
 * @LastEditTime: 2023-08-08 14:28:58
 * @FilePath: /h5/src/utils/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import axios from 'axios'
import router from '@/router'

let loadingInstance
const codeVerificationArray = [200, 0, '200', '0']

const CODE_MESSAGE = {
    0: '未可知错误，可能是因为后端不支持跨域CORS、接口地址不存在等问题引起',
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',    
    400: '发出信息有误',
    401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
    402: '令牌过期',
    403: '请确认您的账号是否正确，且有权限访问该资源',
    404: '访问资源不存在',    
    500: '服务器发生错误',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
  }


  const handleData = async ({ data, status = 0, statusText }) => {
    if (loadingInstance) loadingInstance.close()
    // 若data.code存在，覆盖默认code    
    let code = data && data['code'] ? parseInt(data['code']) : status   
    // 若code属于操作正常code，则code修正为200
    if (codeVerificationArray.indexOf(code) + 1) code = 200    
    switch (code) {
      case 200:
      case 201:
        // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
        // 例如响应内容：
        // 错误内容：{ code: 1, msg: '非法参数' }
        // 正确内容：{ code: 200, data: {  }, msg: '操作正常' }
        // return data
        return data
      case 401:        
        router.push('/login')
        return false
    }
    // 异常处理
    // 若data.msg存在，覆盖默认提醒消息
    const errMsg = `${
      data && data['message']
        ? data['message']
        : CODE_MESSAGE[code]
        ? CODE_MESSAGE[code]
        : statusText
    }`
    //console.log(errMsg)
    //Vue.prototype.$baseMessage(errMsg, 'error', 'vab-hey-message-error')  
    Vue.prototype.$toast(errMsg)
    //.$notify.toast(errMsg)  
    return Promise.reject(data).catch(() => {})
  }


const service=axios.create({
    //baseURL:'http://lhl.wolfeng.com/api/',
    //baseURL:'https://lhl.shoremagic.design/api/',    
    baseURL:'https://lhl.o2orobot.com/api/', 
    //timeout:1000*60,
})

service.interceptors.request.use(config=>{
    config.crossDomain=true
    //config.withCredentials = true
    const token = localStorage.getItem('token')
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    config.headers['Content-Type']='application/json'    
    config.headers['Accept']='*/*'     
    return config
},
(error) => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
        const { response = {} } = error
        return handleData(response)
    }
)

export default service